import React from 'react';
import './contact.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className="container" id='contactus'>
      <div className="contact-container">
        <h1 data-aos="fade-up">Kontaktieren Sie Uns</h1>

        <section className="contact-section" data-aos="fade-up" data-aos-delay="100">
          <h2>Unser Standort</h2>
          <div className="contact-info">
            <FaMapMarkerAlt className="contact-icon" />
            <p>Dudenstraße 6, 36251 Bad Hersfeld</p>
          </div>
        </section>

        <section className="contact-section" data-aos="fade-up" data-aos-delay="200">
          <h2>Telefon & E-Mail</h2>
          <div className="contact-info">
            <FaPhoneAlt className="contact-icon" />
            <p>+49 6621 4374982</p>
          </div>
          <div className="contact-info">
            <FaEnvelope className="contact-icon" />
            <p>support@exabyte-systems.com</p>
          </div>
        </section>

        <section className="contact-section" data-aos="fade-up" data-aos-delay="300">
          <h2>Kontaktformular</h2>
          <form className="contact-form">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">E-Mail:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Nachricht:</label>
            <textarea id="message" name="message" required></textarea>

            <button type="submit" className="contact-button">Absenden</button>
          </form>
        </section>

        <section className="contact-section" data-aos="fade-up" data-aos-delay="400">
       
          
          
        </section>
      </div>
    </div>
  );
}

export default Contact;
