import React from 'react';
import './aboutus.css';
import { Parallax } from 'react-parallax';
import imagePara from '../../assets/schreibtisch.jpg'

const AboutUs = () => {
  return (
   

        <div className="container" id="about">
          <div className="about-us" style={{ marginTop: '15vh' }} data-aos="fade-up">
            <h2 data-aos="fade-down">Wer Wir Sind</h2>
            <div className="about-sections">
              <div className="about-card" data-aos="fade-up" data-aos-delay="100">
                <div className="about-header">Unsere Geschichte</div>
                <p>Wir haben uns auf den Weg gemacht, um innovative Weblösungen zu entwickeln und unsere Expertise in modernen Technologien einzusetzen. Hier erfahren Sie, wie wir begonnen haben und welche Meilensteine wir erreicht haben.</p>
              </div>
              <div className="about-card" data-aos="fade-up" data-aos-delay="200">
                <div className="about-header">Unser Team</div>
                <p>Unser Team besteht aus leidenschaftlichen Entwicklern und Designern, die sich kontinuierlich weiterbilden und neue Technologien ausprobieren. Lernen Sie unsere Teammitglieder kennen und erfahren Sie mehr über ihre individuellen Fähigkeiten.</p>
              </div>
              <div className="about-card" data-aos="fade-up" data-aos-delay="300">
                <div className="about-header">Unsere Mission & Vision</div>
                <p>Unsere Mission ist es, durch maßgeschneiderte Lösungen echten Mehrwert für unsere Kunden zu schaffen. Wir streben danach, innovative und effiziente Webtechnologien zu nutzen, um Ihre Projekte zum Erfolg zu führen.</p>
              </div>
              <div className="about-card" data-aos="fade-up" data-aos-delay="400">
                <div className="about-header">Warum Wir?</div>
                <p>Wir kombinieren technisches Know-how mit kreativem Design, um Ihnen die besten Lösungen anzubieten. Entdecken Sie, warum unser Ansatz einzigartig ist und wie wir Ihre Anforderungen verstehen und umsetzen.</p>
              </div>
            </div>
          </div>
       
  
    </div>
  );
}

export default AboutUs;