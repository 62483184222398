import React from 'react';
import './portfolio.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaMobileAlt, FaTools, FaRegSmile } from 'react-icons/fa'; // Importiere Icons von react-icons
import image1 from "../../assets/biergartenzumhafen.png"
import image2 from "../../assets/exabytewebsite.png"
import image3 from "../../assets/examain.png"
AOS.init();

const Portfolio = () => {
  return (
    <div className="container" id='portfolio'>
      <div className="portfolio-container">
        <h1 data-aos="fade-up">Unsere Arbeiten</h1>

        <section className="portfolio-section" data-aos="fade-up" data-aos-delay="100">
          <h2>Projekte</h2>
          <div className="portfolio-projects">
            <img src={image1} alt="Projekt 1" className="portfolio-image" />
            <img src={image2} alt="Projekt 2" className="portfolio-image" />
            <img src={image3} alt="Projekt 3" className="portfolio-image" />
          </div>
        </section>

        <section className="portfolio-section" data-aos="fade-up" data-aos-delay="200">
          <h2>Unsere Herangehensweise</h2>
          <div className="portfolio-approach">
            <div className="approach-card" data-aos="fade-up" data-aos-delay="300">
              <div className="approach-icon">
                <FaMobileAlt />
              </div>
              <h3>Mobile First</h3>
              <p>Wir legen großen Wert auf ein hervorragendes Nutzererlebnis auf mobilen Geräten, da immer mehr Menschen ihre Smartphones nutzen.</p>
            </div>
            <div className="approach-card" data-aos="fade-up" data-aos-delay="400">
              <div className="approach-icon">
                <FaTools />
              </div>
              <h3>LTS - Longterm Support</h3>
              <p>Langfristige Unterstützung und regelmäßige Updates gewährleisten, dass Ihre Website immer auf dem neuesten Stand bleibt.</p>
            </div>
            <div className="approach-card" data-aos="fade-up" data-aos-delay="500">
              <div className="approach-icon">
                <FaRegSmile />
              </div>
              <h3>Easy to Use</h3>
              <p>Wir gestalten unsere Lösungen benutzerfreundlich, damit Sie sie leicht verwalten und bedienen können, ohne technische Kenntnisse vorauszusetzen.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Portfolio;
