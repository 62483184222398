import React from 'react';
import './Hero.css';
import HeroImage from '../../assets/hero.jpg'; // Stelle sicher, dass du das Bild im richtigen Pfad hast

const Hero = () => {
    return (
        <div id="hero" className="hero">
            <div className="hero-overlay"></div>
            <div className="hero-content">
                {/* Erster Teil der h1 Überschrift */}
                <h1 className="hero-title-part1">Willkommen bei </h1>
                
                {/* Zweiter Teil der h1 Überschrift */}
                <h1 className="hero-title-part2">Exabyte Systems</h1>
                
                <p className="hero-description">Ihr Partner für professionelle Website-Entwicklung. Lassen Sie uns gemeinsam Ihre Ideen verwirklichen.</p>
                <a href="#services" className="cta-button">Mehr erfahren</a>
            </div>
            <div className="hero-image-container">
                <img src={HeroImage} alt="Hero" className="hero-image" />
            </div>
        </div>
    );
}

export default Hero;
