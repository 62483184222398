import React from 'react';
import Slider from 'react-slick';
import './testimonails.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonialsData = [
  {
    text: "Bewertungen in Bearbeitung!",
    author: "In Progress",
    image: "https://via.placeholder.com/150" // Platzhalterbild oder eigenes Bild
  },
  {
    text: "Bewertungen in Bearbeitung!",
    author: "In Progress",
    image: "https://via.placeholder.com/150" // Platzhalterbild oder eigenes Bild
  },
  {
    text: "Bewertungen in Bearbeitung!",
    author: "In Progress",
    image: "https://via.placeholder.com/150" // Platzhalterbild oder eigenes Bild
  },
  {
    text: "Bewertungen in Bearbeitung!",
    author: "In Progress",
    image: "https://via.placeholder.com/150" // Platzhalterbild oder eigenes Bild
  }

];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Zeigt 3 Testimonials auf einmal
    slidesToScroll: 1, // Scrollt durch 1 Slide pro Klick
    responsive: [
      {
        breakpoint: 1024, // Für große Bildschirme
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768, // Für mittlere Bildschirme
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480, // Für kleine Bildschirme
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const defaultImage = "https://via.placeholder.com/150"; // Standardbild

  return (
    <div className="container">
      <div className="pricing">
        <h2 className="testimonials-heading">Was unsere Kunden sagen</h2>
        <div className="testimonials-carousel">
          <Slider {...settings}>
            {testimonialsData.map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <img 
                  src={testimonial.image || defaultImage} 
                  alt={testimonial.author} 
                  className="testimonial-image"
                />
                <p className="testimonial-text">"{testimonial.text}"</p>
                <h5 className="testimonial-author">{testimonial.author}</h5>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
