import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './rechtliches.css';

const Rechtliches = () => {
  return (
    <div className="impressum-container mt-5">
      <div className="impressum-row">
        <div className="impressum-col">
          <div className="impressum-border p-4 bg-light">
            <h1 className="impressum-title mb-4">Impressum</h1>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">Angaben gemäß § 5 TMG</h2>
              <p>
                Michael H.G. Schmidt<br />
                 Dudenstraße 6, 36251 Bad Hersfeld<br />
                36251 Bad Hersfeld<br />
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">Kontakt</h2>
              <p>
                Telefon: 0157 718 42593 <br />
                E-Mail: <a href="mailto:gastro.hafen@gmail.com">mschmidt@exabyte-systems.com</a><br />
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">Umsatzsteuer-ID</h2>
              <p>
                Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />
               DE2846632330
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
              <p>
                Michael H.G. Schmidt<br />
                Dudenstraße 6, 36251 Bad Hersfeld<br />
                36251 Bad Hersfeld<br />
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">Haftungsausschluss</h2>
              <h3 className="impressum-subheading h5">Haftung für Inhalte</h3>
              <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              </p>
              <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              </p>

              <h3 className="impressum-subheading h5 mt-4">Haftung für Links</h3>
              <p>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
              </p>
              <p>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">Urheberrecht</h2>
              <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
              </p>
              <p>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">Streitbeilegung</h2>
              <p>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: 
                <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
                  https://ec.europa.eu/consumers/odr
                </a>.<br />
                Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>
              <p>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
              </p>
            </section>

            <h1 className="impressum-title mb-4">Datenschutzerklärung</h1>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">1. Datenschutz auf einen Blick</h2>
              <p>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unten aufgeführten Datenschutzerklärung.
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">2. Verantwortliche Stelle</h2>
              <p>
                Verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
              </p>
              <p>
                Michael H.G. Schmidt<br />
                Dudenstraße 6<br />
                36251 Bad Hersfeld<br />
                Telefon: +49 6621 4374982<br />
                E-Mail: mschidt@exabyte-systems.com
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h2>
              <h3 className="impressum-subheading h5">a) Beim Besuch der Website</h3>
              <p>
                Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
              </p>
              <ul>
                <li>IP-Adresse des anfragenden Rechners,</li>
                <li>Datum und Uhrzeit des Zugriffs,</li>
                <li>Name und URL der abgerufenen Datei,</li>
                <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
                <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
              </ul>
              <p>
                Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
              </p>
              <ul>
                <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
                <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zu weiteren administrativen Zwecken.</li>
              </ul>
              <p>
                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
              </p>

              <h3 className="impressum-subheading h5">b) Bei Nutzung unseres Kontaktformulars</h3>
              <p>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten (z.B. Name, E-Mail-Adresse, Betreff, Nachricht) zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">4. Weitergabe von Daten</h2>
              <p>
                Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
              </p>
            </section>
            
            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">5. Betroffenenrechte</h2>
              <ul>
                <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen,</li>
              </ul>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">6. Widerspruchsrecht</h2>
              <p>
                Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen.
              </p>
            </section>
            
            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">7. Datensicherheit</h2>
              <p>
                Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe.
              </p>
            </section>

            <section className="impressum-section mb-4">
              <h2 className="impressum-heading h4">8. Aktualität und Änderung dieser Datenschutzerklärung</h2>
              <p>
                Diese Datenschutzerklärung ist aktuell gültig und hat den Stand September 2024. Durch die Weiterentwicklung unserer Website oder aufgrund geänderter gesetzlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rechtliches;
